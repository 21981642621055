import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import "./css/Search.css";

const SearchForm = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchVideos, setSearchVideos] = useState([]);
 
  useEffect(() => {
    if(searchValue.length != 0) {
        const delayDebounceFn = setTimeout(() => {
          fetch("https://auth.gr/wp-json/wp/v2/posts?categories=322&search=" + searchValue + "&_embed")
            .then((response) => response.json())
            .then((data) => {
              setSearchVideos(data);
            });
        }, 1000);
        return () => clearTimeout(delayDebounceFn);
      }
  }, [searchValue]);

  const searchVideosHandler = (e) => {
    setSearchValue(e.target.value);
  };

  const goToSearchResults = (e) => {
    e.preventDefault();
    window.location.href = "https://audiovisual.auth.gr/browse?search_value=" + searchValue; 
  };

  return (
    <React.Fragment>
      <form id="search-form" onSubmit={goToSearchResults}>
        <div className="uk-inline">
          <label htmlFor="search-field" className="hidden-label">
            Search
          </label>
          <input
            type="text"
            id="search-field"
            className="uk-input align-right"
            aria-label="search-type"
            onChange={searchVideosHandler}
          />
          <FontAwesomeIcon icon={faMagnifyingGlass} className="search-icon" />
        </div>
      </form>
    </React.Fragment>
  );
};

export default SearchForm;