import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Navigation from "../Navigation";
import ItemVideo from "../ItemVideo";
import noVideo from "../../images/video-poster.png";
import "../css/Pages.css";
import Moment from "moment";
import useSearchParamsState from "../../hooks/useSearchParamsState"; //custom hook για να διατηρούμε τα ορίσματα στο url
import CreateUnitsSelect from "../CreateUnitsSelect";
import Select from 'react-select'
import Pagination from "../Pagination";
import Footer from "../Footer";
import NoResultsMessage from "../NoResultsMessage";
import Spinner from "../Spinner";

const Browse = (props) => {
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  //Αποθηκεύουμε την παράμετρο απο το url
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  //for pagination
  const [nrofpages, setNumberofpage] = useState(1); 
  const [totalResults, setTotalResults] = useState(1);
  const [page, setPage] = useState(1); 
  const search_value = params.get("search_value");
  const video_category = params.get("vc");
  const unit = params.get("unit");
  const year = params.get("year");
  const type = params.get("type");
  const pageNumber = params.get("page");
  const [searchURL, setSearchURL] = useState("");
  const [vcURL, setVcURL] = useState("");
  const [unitURL, setUnitURL] = useState("");
  const [yearURL, setYearURL] = useState("");
  const [typeURL, setTypeURL] = useState("");
  const [changeSelect, setChangeSelect] = useState(false);
  const [searchParam, setSearchParam] = useSearchParamsState("search_value","");
  const [vcParam, setVcParam] = useSearchParamsState("vc", "all");
  const [unitParam, setUnitParam] = useSearchParamsState("unit", "all");
  const [yearParam, setYearParam] = useSearchParamsState("year", "all");
  const [typeParam, setTypeParam] = useSearchParamsState("type", "all");
  const [pageParam, setPageParam] = useSearchParamsState("page", 1);
  let strValue = "";
  let searchStr = "";
  let vcStr = "";
  let unitStr = "";
  let yearStr = "";
  let typeStr = "";
  let tempUrl ="https://auth.gr/wp-json/wp/v2/posts?categories=322";
  let finalUrl;
  const currentYear = Moment().format("YYYY");
  const TextfieldFiltersHandler = (event) => {
    if (event.target.name === "search_value") {
      setSearchParam(event.target.value);
      setPage(1);
      setChangeSelect(true);  
    }
  };
  
	//with react select library
	const filtersHandler = (event,nameComp) => {
    setPage(1);
    setPageParam(1);
	  setChangeSelect(true);

    if (nameComp.name === "vc") { 
      setVcParam(event.value);
    } else if (nameComp.name === "unit") {
      setUnitParam(event.value);  
    } else if (nameComp.name === "year") {
      setYearParam(event.value);
    } else if (nameComp.name === "type") {
      setTypeParam(event.value);  
    }        
  };
  
  useEffect(() => {
    document.title = 'Βιβλιοθήκη - audiovisual.auth';
	  setChangeSelect(false);
    
    if (params.has("page")) { 
      if (pageNumber != "") {
        setPage(pageNumber);
      }
      
      if(pageNumber > 1 && changeSelect) {
        setPage(1);
        setPageParam(1);
      }		
    }
        
    if (params.has("search_value")) {
      if (search_value != "") {
        searchStr = "search=" + search_value;
        setSearchURL(search_value);
      } else {
        searchStr = "";
        setSearchURL("");
      }
    }

    if (params.has("vc")) {
      if (video_category != "all") {
        vcStr = "video_category=" + video_category;
        setVcURL(video_category);
      } else {
        vcStr = "";
        setVcURL("");
      }
    }

    if (params.has("unit")) {
      if (unit != "all") {
        unitStr = "auth_units=" + unit;
        setUnitURL(unit);
      } else {
        unitStr = "";
        setUnitURL("");
      }
    }

    if (params.has("year")) {
      if (year != "all") {
        yearStr =
          "orderby=date&order=desc&before=" +
          year +
          "-12-31T00:00:00&after=" +
          year +
          "-01-01T00:00:00";
        setYearURL("year");
      } else {
        yearStr = "";
        setYearURL("noYear");
      }
    }

    if (params.has("type")) {
      if (type == "videos") {
        typeStr = "live=0";
        setTypeURL("videos");
      } else if (type == "live-streaming") {
        typeStr = "live=1";
        setTypeURL("live-streaming");
      } else if (type == "all") {
        typeStr = "";
        setTypeURL("all");
      }
    }

    if (searchStr.length != 0) {
      tempUrl += "&" + searchStr;
    }
    if (vcStr.length != 0) {
      tempUrl += "&" + vcStr;
    }

    if (unitStr.length != 0) {
      tempUrl += "&" + unitStr;
    }

    if (yearStr.length != 0) {
      tempUrl += "&" + yearStr;
    }

    if (typeStr.length != 0) {
      tempUrl += "&" + typeStr;
    }
    finalUrl = tempUrl + "&_embed&per_page=16&page=" + page;
    
    const delayDebounceFn = setTimeout(() => {
      setVideos([]);
      setIsLoading(true);
      fetch(finalUrl)
        .then((response) => {
          setNumberofpage(response.headers.get("X-WP-TotalPages"));
          setTotalResults(response.headers.get("X-WP-Total"));
          return response.json()
        })
        .then((data) => {
          setVideos(data);  
          setIsLoading(false);
        });
    }, 1000);
    return () => clearTimeout(delayDebounceFn);

  }, [type, year, video_category, unit, search_value, page, changeSelect]);

	const yearArray = [];
	yearArray.push({ value: 'all', label: 'Επιλέξτε έτος...' })
	for (let i = currentYear ; i >= 2010; i--) {
		yearArray.push({ value: i, label: i },);
	}
	
	const categoryOptionArray = [
	  { value: 'all', label: 'Επιλέξτε κατηγορία...' },
	  { value: 'rectory', label: 'Πρυτανικές Αρχές' },
	  { value: 'events', label: 'Εκδηλώσεις' },
	  { value: 'meetings', label: 'Ημερίδες' },
	  { value: 'conference', label: 'Συνέδρια' },
	  { value: 'orkomosia', label: 'Ορκωμοσίες' },
	  { value: 'workshops', label: 'Workshops' },
	]
	
	const typeOptionArray = [
	  { value: 'all', label: 'Επιλέξτε τύπο...' },
	  { value: 'videos', label: 'Videos' },
	  { value: 'live-streaming', label: 'Live Streaming' },
	]	
						
	const checkdefault = (OptionArray, SelectedValue) => {
		let label="";
		
		OptionArray.map((item) => {
			if(item.value == SelectedValue) {
				label = item.label;
			}
		});
		
		return { value: SelectedValue, label: label };
	};                    
       
  return (
    <React.Fragment>
      <div className="uk-grid" data-uk-grid>
        <div className="uk-width-small uk-visible@m">
          <Navigation />
        </div>
        <div className="uk-width-expand">
          <h2>Βιβλιοθήκη</h2>
          <div className="filters">
            <form>
              <div className="uk-grid" data-uk-grid>
                <div className="uk-width-1-1 uk-width-1-5@m">
                  <label htmlFor="browse-search-field" className="uk-hidden">Αναζήτηση</label>
                  <input
                    type="text"
                    id="browse-search-field"
                    className="uk-input"
                    aria-label="search-type"
                    name="search_value"
                    placeholder="Πληκτρολογήστε όρο αναζήτησης..."
                    onChange={TextfieldFiltersHandler}
                    value={search_value == null ? "" : search_value}
                  />
                </div>

                <div className="uk-width-1-1 uk-width-1-5@m">
                    <label htmlFor="video-category-field" className="uk-hidden"> Κατηγορία </label>
                     
                    <Select 
                      options={categoryOptionArray} 
                      inputId="video-category-field"
                      name="vc"
                      onChange={filtersHandler}
                      value={video_category == null ? { label: "Επιλέξτε κατηγορία...", value: "all" } : checkdefault(categoryOptionArray, video_category)}
                    />
                </div>

                <div className="uk-width-1-1 uk-width-1-5@m">
                  <CreateUnitsSelect unitProp={unit} filter={filtersHandler} />
                </div>

                <div className="uk-width-1-1 uk-width-1-5@m">
                  <label htmlFor="video-year-field" className="uk-hidden"> Έτος </label>
				  
                  <Select 
                    options={yearArray} 
                    inputId="video-year-field"
                    name="year"
                    onChange={filtersHandler}
                    value={year == null ? { label: "Επιλέξτε έτος...", value: "all" } : checkdefault(yearArray, year)}
                  />
                </div>

                <div className="uk-width-1-1 uk-width-1-5@m">
                  <label htmlFor="video-type-field" className="uk-hidden"> Τύπος </label>
                  <Select 
                    options={typeOptionArray} 
                    inputId="video-type-field"
                    name="type"
                    onChange={filtersHandler}
                    value={(type == null || type == "all") ? { label: "Επιλέξτε τύπο...", value: "all" } : checkdefault(typeOptionArray, type)}
                  />
                </div>
              </div>
            </form>
          </div>

          {videos.length != 0 && <div className="number-of-results"> Βρέθηκαν {totalResults} αποτελέσματα </div> }
  
          {videos.length != 0 ? (
              <div className="uk-grid uk-grid-medium uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-2@m uk-child-width-1-3@l uk-child-width-1-4@xl" data-uk-grid>
            {videos.map((video) => (
              <ItemVideo
                id={video.id}
                key={video.id}
                video_category={`./?vc=${video.acf.video_category ? video.acf.video_category.value : ''}`}
                video_category_label={video.acf.video_category ? video.acf.video_category.label : ''}
                video_date={video.date}
                video_image={
                  video._embedded["wp:featuredmedia"]
                    ? video._embedded["wp:featuredmedia"][0].source_url
                    : noVideo
                }
                video_title={video.title.rendered}
                video_url={`/video/${video.id}`}
                video_live={video.acf.video_live}
                live_until={video.acf.live_until} //Y-m-d G:i:s
              />
              ))}
              </div>
            ) : (
              <div> 
                { isLoading ? <Spinner /> : <NoResultsMessage message="Δεν βρέθηκαν βίντεο"/> } 
              </div>
            )}
              
		      { !isLoading && nrofpages > 1 && 
            <Pagination
              nrOfPages={nrofpages}
              currentpage={page}
              onSelectPage={n => {
                setPage(n);
              }}
            />    
		      }

        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};
export default Browse;