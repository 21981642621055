import React, { useState } from "react";
import "./css/Header.css";
import logo from "../images/audiovisual.auth.svg";
import logoLight from "../images/audiovisual.auth-light.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon } from "@fortawesome/free-solid-svg-icons";
import { ThemeContext, themes } from "../UI/themeContext";
import Search from "./Search";
import NavigationOffCanvas from "./NavigationOffCanvas";
import { BrowserRouter } from 'react-router-dom';

const Header = (props) => {
  const [enteredSearchValue, setEnteredSearchValue] = useState("");

  const saveSearchValue = (enteredValue) => {
    setEnteredSearchValue(enteredValue);
  };
     
  const mode = JSON.parse(localStorage.getItem("dark-mode"));
  
  const [darkMode, setDarkMode] = useState(mode); //mode ? true : false

  return (
    <div className="header">
      <div className="uk-grid" data-uk-grid>
        <div className="uk-width-1-1 uk-width-1-2@m">
          <a href="/"> 
            <img src={darkMode ? logo : logoLight} alt="main logo" />
          </a>
        </div>

        <div className="uk-width-1-1 uk-width-1-2@m">
          <div className="area-right">
            <div className="uk-hidden@m">
              <BrowserRouter>
                <NavigationOffCanvas/>
              </BrowserRouter>
              </div>
            
            <Search />
            <div className="dark-mode align-right">
              <ThemeContext.Consumer>
                {({ changeTheme }) => (
                  <FontAwesomeIcon
                    icon={faMoon}
                    onClick={() => {
                      setDarkMode(!darkMode);
                      changeTheme(darkMode ? themes.light : themes.dark);
                      //console.log(darkMode);
                      if (darkMode === false) {
                        localStorage.setItem("dark-mode", JSON.stringify(true));
                      } else {
                        localStorage.setItem(
                          "dark-mode",
                          JSON.stringify(false)
                        );
                      }
                    }}
                  />
                )}
              </ThemeContext.Consumer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;