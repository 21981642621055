import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import VideoJS from "../VideoJS";
import { Link } from "react-router-dom";
import Navigation from "../Navigation";
import "../css/Pages.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import noVideo from "../../images/video-poster.png";
import { faCalendarDays, faCropSimple, faShareNodes } from "@fortawesome/free-solid-svg-icons";
import videoPoster from "../../images/video-poster.png";
import VideoViews from "../VideoViews";
import UpdateViewsCounter from "../UpdateViewsCounter";
import "moment/locale/el";
import ConvertDate from "../ConvertDate";
import Footer from "../Footer";
import MoreUnitVideos from "../MoreUnitVideos";
import MoreVideoCategoryVideos from "../MoreVideoCategoryVideos";
import ShareBox from "../ShareBox";

const VideoDetails = () => {
  const [video, setVideo] = useState([]);
  //αποθηκέουμε το id απο το url (αντιστοιχο GET στην php)
  const { id } = useParams();
  const parser = new DOMParser();

  let moreVideosUnitID;
  /* Η συνάρτηση saveVideos στέλνεται σαν 2 όρισμα στο custom hook 'use-fetch-videos'.
   * Παίρνει σαν όρισμα τα video Objects τα οποία είναι τα data που μας φέρνει το fetch απο το endpoint του wordpress
   * και τα αποθηκεύει με τη useState στο videos
   */
  let sourceUrl = "";
  const playerReference = React.useRef(null);
  let videoLink, videoUnits, videoLive, VideoTitle = "";
  let currentIndex = 0;
  
  /* Καλούμε το custom hook useFetchVideos και στέλνουμε 2 ορίσματα. Το πρώτο είναι το url απο το endpoint που θέλουμε
   * να διαβάσουμε και το 2ο είναι η συνάρτηση saveVideo την οποία υλοποιούμε παραπάνω.
   * Το custom hook επιστρέφει τρία στοιχεία. Το isLoading, error και fetchVideosHandler. Τα αποθηκεύω και αλλάζω το
   * όνομα του fetchVideosHandler σε fetchVideos
   */
 
  useEffect(() => {
    fetch("https://auth.gr/wp-json/wp/v2/posts/?include=" + id + "&_embed")
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        setVideo(data);  
      });
  }, [id]);


  video.map((mapItem) => {
    videoLink = mapItem.acf.video_url;
    videoUnits = mapItem.acf.auth_units;
    videoLive = mapItem.acf.video_live;
    VideoTitle = mapItem.title.rendered;
    document.title = mapItem.title.rendered;

    if (mapItem._embedded.hasOwnProperty("wp:featuredmedia")) {
      const urls = mapItem._embedded["wp:featuredmedia"].map(
        (videoItem) => videoItem
      );
      sourceUrl = urls[0].source_url;
    }
});
    
   // All sources, in an actual case will be Media, OCMedia and VOD of message.
  let sources = [
    'https://media.auth.gr/ScheduleRecording/' + videoLink  + "/playlist.m3u8",	 // Media
    'https://ocmedia.auth.gr/OpenCourses/' + videoLink + "/playlist.m3u8",	 // Media
    'https://media.auth.gr/vod/acsup-offline-GR.mp4/playlist.m3u8' // Message
  ];

  let videoJsOptions = {};

  if ( videoLive === true ) {
    videoJsOptions = {
      autoplay: true,
      controls: true,
      responsive: true,
      fluid: true,
      sources: [
        {
          src: sources[0],
          type: "application/x-mpegurl",
        },
      ],
      poster: videoPoster,
    };
  } else {
    videoJsOptions = {
      autoplay: true,
      controls: true,
      responsive: true,
      fluid: true,
      sources: [
        {
          src: "https://media.auth.gr:443/vod/_definst_/" + videoLink +"/playlist.m3u8",
          type: "application/x-mpegurl",
        },
      ],
      poster: videoPoster,
    };
  }

    
  const playerReady = (player) => {
    playerReference.current = player;
    
    player.on("waiting", () => {
      console.log("Video Player is waiting");
    });
    player.on("dispose", () => {
      console.log("Video player will dispose");
    });
    
  };

  let videoCategoryValue = video.map((item) => ( item.acf.video_category.value ));
  let videoCategoryLabel = video.map((item) => ( item.acf.video_category.label ));

  return (
    <div className="video-details">
      <UpdateViewsCounter videoID={id} />
      <div className="uk-grid" data-uk-grid>
        <div className="uk-width-small uk-visible@m">
          <Navigation />
        </div>
        <div className="uk-width-expand">
          <div className="uk-grid" data-uk-grid>
            <div className="uk-width-1-1 uk-width-3-5@m">
              {video.map((item) => (
                <div className="video-data" key={id}>
                  <div className="video-player">
                    <VideoJS options={videoJsOptions} onReady={playerReady} />
                  </div>
                  <div className="details-box">
                    <h3> {parser.parseFromString(`${item.title.rendered}`, "text/html").body.textContent}</h3>

                     {item.content.rendered && 
                      <div className="video-description">
                        { parser.parseFromString(`${item.content.rendered}`, "text/html").body.textContent } 
                     </div>
                      }   
				            <div className="uk-grid" data-uk-grid>
                      <div className="uk-width-1-1 uk-width-3-5@m">
                        <div className="block-box">
                          <div className="box-dark">
                            <FontAwesomeIcon icon={faCalendarDays} className="event-date"/>
                            <ConvertDate postDate={item.date} />
                          </div>
                          {item.acf.video_category.label && (
                            <Link className="box-blue" to={'/browse?vc=' + item.acf.video_category.value}>{item.acf.video_category.label}</Link>
                          )}
                          {videoUnits && (
                            <React.Fragment>
                              {videoUnits.map((unit) => (
                                <Link className="box-dark" to={'/browse?unit=' + unit.ID} key={unit.ID}>
                                 <span>
                                    {unit.post_title ? unit.post_title : ""}
                                  </span>
                                </Link>
                              ))}
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                      <div className="uk-width-1-1 uk-width-2-5@m">
						            <div className="flex-box flex-right">
                            <div className="box-dark">
                              <Link to="#toggle-shareBox" data-uk-toggle="target: #toggle-shareBox; animation: uk-animation-slide-top;cls: uk-hidden ">
                                <FontAwesomeIcon icon={faShareNodes} className="share-icon"/>
                              </Link>
                            </div>
                            <div className="box-dark"><VideoViews videoID={id} /></div>
                        </div>
                      </div>
                    </div>
                    <ShareBox bgColor="transparent" iconColor="#fff" videoID={id} videoTitle={VideoTitle} />               
                  </div>
                  {item.acf.nref_event.ID && (
                    <div className="details-box">
                      <div className="uk-grid" data-uk-grid>
                        <div className="uk-width-1-1">
                          {video.map((item) => (
                            <div key={item.acf.nref_event.ID} className="uk-grid event-details" data-uk-grid>
                              <div className="uk-width-1-1 uk-width-1-5@s uk-width-1-6@m">
                                {item.sourceUrl && <img src={item.sourceUrl} />}
                                {! item.sourceUrl && <img src={noVideo} /> }
                              </div>
                              <div className="uk-width-1-1 uk-width-4-5@s uk-width-5-6@m">
                                <p>Σχετικη εκδηλωση:</p>
                                <p className="info-title">
                                  {
                                    <Link to={item.acf.nref_event.guid} target="_blank">
                                      {
                                        parser.parseFromString(
                                          `${item.acf.nref_event.post_title}`,
                                          "text/html"
                                        ).body.textContent
                                      }
                                    </Link>
                                  }
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="uk-width-1-1 uk-width-2-5@m">
              <div className="sidebar">
                {videoUnits &&
                  (<div className="details-box">
                    <h3 className="divider">Περισσότερα Βίντεο Μονάδας</h3>
                    <MoreUnitVideos units= { videoUnits }/>
                  </div>)
                }
                
                <div className="details-box">
                  <h3 className="divider">Περισσότερα Βίντεο</h3>
                  <MoreVideoCategoryVideos categoryValue = { videoCategoryValue } categoryLabel={videoCategoryLabel}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default VideoDetails;