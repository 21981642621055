import React, { useState, useEffect } from "react";
import Navigation from "../Navigation";
import Carousel from "../Carousel";
import "../css/Pages.css";
import Footer from "../Footer";
const Categories = (props) => {

  const videoCategories = [
	  { value: 'rectory', label: 'Πρυτανικές Αρχές' },
	  { value: 'events', label: 'Εκδηλώσεις' },
	  { value: 'meetings', label: 'Ημερίδες' },
	  { value: 'conference', label: 'Συνέδρια' },
	  { value: 'orkomosia', label: 'Ορκωμοσίες' },
	  { value: 'workshops', label: 'Workshops' },
	]

  useEffect(() => {
    document.title = 'Κατηγορίες - audiovisual.auth';
  }, []);

  return (
    <React.Fragment>
      <div className="uk-grid" data-uk-grid>
        <div className="uk-width-small uk-visible@m">
          <Navigation />
        </div>

        <div className="uk-width-expand">
          <div className="categories-page">
            <h2>Κατηγορίες</h2>
            
            {videoCategories.map((category) => 
                <Carousel
                  key={category.value}
                  title={category.label}
                  category="categories"
                  categoryValue={category.value}
                  fetchUrl={`https://auth.gr/wp-json/wp/v2/posts?categories=322&video_category=${category.value}&_embed&per_page=12`}
                /> 
            )}
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};
export default Categories;