import React from "react";
import Moment from "moment";
import "moment/locale/el";

const ConvertDate = (props) => {
  Moment.locale("el");

  let localeData = Moment.updateLocale("el", {
    months: [
      { 1: "Ιανουαρίου" },
      { 2: "Φεβρουαρίου" },
      { 3: "Μαρτίου" },
      { 4: "Απριλίου" },
      { 5: "Μαϊου" },
      { 6: "Ιουνίου" },
      { 7: "Ιουλίου" },
      { 8: "Αυγούστου" },
      { 9: "Σεπτεμβρίου" },
      { 10: "Οκτωβρίου" },
      { 11: "Νοεμβρίου" },
      { 12: "Δεκεμβρίου" },
    ],
  });
  let monthNumbers = localeData.months();
  let videoDate = "";
  const postDate = props.postDate;

  return (
    <React.Fragment>
      {monthNumbers.map((number) => {
        const monthKeys = Object.keys(number);
        const monthValues = Object.values(number);

        if (monthKeys.toString() == Moment(postDate).format("M")) {
          videoDate =
            Moment(postDate).format("D") +
            " " +
            number[monthKeys.toString()] +
            " " +
            Moment(postDate).format("YYYY");
        }
      })}

      <span>{videoDate}</span>
    </React.Fragment>
  );
};

export default ConvertDate;