import React, { useState, useEffect } from 'react';
import { ThemeContext, themes } from './themeContext';

const ThemeContextWrapper = (props) => {
  const [theme, setTheme] = useState(themes.dark);

  function changeTheme(theme) {
    setTheme(theme);
  }

  useEffect(() => { 
    const mode = JSON.parse(localStorage.getItem('dark-mode'));
    if (mode) { 
      if (mode == false) {
        changeTheme(themes.light);
      } else {
        changeTheme(themes.dark);
      }
    } else {
      // Setup dark/light mode for the first time based on user's system preferences
      changeTheme(window.matchMedia('(prefers-color-scheme: dark)').matches ? themes.dark : themes.light);
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => changeTheme(e.matches ? themes.dark : themes.light));
    }
    
  }, []);

  useEffect(() => {
    switch (theme) {
      case themes.light:
        document.body.classList.add('light-mode');
        break;
      case themes.dark:
        document.body.classList.remove('light-mode');
        break;
      default:
        document.body.classList.add('light-mode');
        break;
    }
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme: theme, changeTheme: changeTheme }}>
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextWrapper;