import React, { useState, useEffect } from "react";
import "../css/Pages.css";
import Navigation from "../Navigation";
import useFetchVideos from "../../hooks/use-fetch-videos";
import ItemVideo from "../ItemVideo";
import noVideo from "../../images/no-video.png";
import Footer from "../Footer";
import NoResultsMessage from "../NoResultsMessage";
import Spinner from "../Spinner";

const Today = (props) => {
  const [videos, setVideos] = useState([]);
  /* Η συνάρτηση saveVideos στέλνεται σαν 2 όρισμα στο custom hook 'use-fetch-videos'.
   * Παίρνει σαν όρισμα τα video Objects τα οποία είναι τα data που μας φέρνει το fetch απο το endpoint του wordpress
   * και τα αποθηκεύει με τη useState στο videos
   */
  const saveVideos = (videosObj) => {
    const loadedVideos = [];

    for (const videoKey in videosObj) {
      let sourceUrl = "";
      if (videosObj[videoKey]._embedded.hasOwnProperty("wp:featuredmedia")) {
        const urls = videosObj[videoKey]._embedded["wp:featuredmedia"].map(
          (videoItem) => videoItem
        );
        sourceUrl = urls[0].source_url;
      }

      loadedVideos.push({
        id: videosObj[videoKey].id,
        text: videosObj[videoKey].title.rendered,
        image: sourceUrl ? sourceUrl : noVideo,
        videoCategory: videosObj[videoKey].acf.video_category,
        videoDate: videosObj[videoKey].date,
        videoLive: videosObj[videoKey].acf.video_live,
      });
    }
    setVideos(loadedVideos);
  };
  /* Καλούμε το custom hook useFetchVideos και στέλνουμε 2 ορίσματα. Το πρώτο είναι το url απο το endpoint που θέλουμε
   * να διαβάσουμε και το 2ο είναι η συνάρτηση saveVideos την οποία υλοποιούμε παραπάνω.
   * Το custom hook επιστρέφει τρία στοιχεία. Το isLoading, error και fetchVideosHandler. Τα αποθηκεύω και αλλάζω το
   * όνομα του fetchVideosHandler σε fetchVideos
   */
  const {
    isLoading,
    error,
    fetchVideosHandler: fetchVideos,
  } = useFetchVideos(
    {
      url: "https://auth.gr/wp-json/wp/v2/posts?categories=322&today=1&_embed&per_page=12",
    },
    saveVideos
  );

  useEffect(() => {
    fetchVideos();
    document.title = 'Προστέθηκαν σήμερα - audiovisual.auth';
  }, [fetchVideos]);

  return (
    <React.Fragment>
      <div className="uk-grid" data-uk-grid>
        <div className="uk-width-small uk-visible@m">
          <Navigation />
        </div>
        <div className="uk-width-expand">
          <h2>Προστέθηκαν σήμερα</h2>  

            {videos.length != 0 ? (
              <div className="uk-grid uk-grid-medium uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-4@m" data-uk-grid>
              {videos.map((video) => (
                <ItemVideo
                  key={video.id}
                  video_image={video.image}
                  video_category={video.videoCategory}
                  video_date={video.videoDate}
                  video_title={video.text}
                  video_url={`/video/${video.id}`}
                  video_live={video.videoLive}
                /> 
              ))}
              </div>
            ) : (
              <div>
                { isLoading ? <Spinner /> : <NoResultsMessage message="Δεν βρέθηκαν βίντεο"/> } 
              </div>
            )}
        </div>
      </div>
      <Footer/>
    </React.Fragment>
  );
};
export default Today;