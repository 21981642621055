import React,{useState,useCallback,useEffect} from "react";
import { Link } from "react-router-dom";
import "./css/ItemVideo.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo, faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { faPlayCircle } from "@fortawesome/free-regular-svg-icons";
import ConvertDate from "./ConvertDate";
import VideoViews from "./VideoViews";
import VideoJS from "./VideoJS";
import useFetchVideos from "../hooks/use-fetch-videos";
import Moment from "moment";

const ItemVideo = (props) => {
	const [isMobile, setIsMobile] = useState(false)
	const videoDate = props.video_date.split("T")[0];
	const[coverImage,setCoverImage] = useState(true);
	const[loadVideo,setLoadVideo] = useState(false);
	const [video, setVideo] = useState([]);
	const id = props.id;
	const parser = new DOMParser();
	const currentDate = Moment().format("YYYY-MM-DD HH:mm:ss"); //Y-m-d G:i:s
	let liveVideo,videoLink,videoUnits = "";	  
  
	const saveVideo = useCallback((videosObj) => {
		const loadedVideo = [];
		loadedVideo.push({
			videoLive: videosObj[0].acf.video_live, 
			videoURL: videosObj[0].acf.video_url,
		});
		setVideo(loadedVideo);
	}, []);
	
	const {
		isLoading,
		error,
		fetchVideosHandler: fetchVideo,
	  } = useFetchVideos(
		{
		  url:
			"https://auth.gr/wp-json/wp/v2/posts/?include=" +
			id +
			"&_embed",
		},
		saveVideo
	  );
	  
	const handleResize = () => {
		if (window.innerWidth < 720) {
			setIsMobile(true)
		} else {
			setIsMobile(false)
		}
	}

	useEffect(() => {
		window.addEventListener("resize", handleResize);
		fetchVideo();
	}, [fetchVideo]);
	
	video.map((item) => {
		liveVideo = item.videoLive;
		videoLink = item.videoURL;
	});	
	  
   const loadSmallVideo = (event) => {
	if(!isMobile) {
		setCoverImage(false);
		setLoadVideo(true);
	}
   }

   const loadCoverImage = (event) => {
	   if(!isMobile) {
		setCoverImage(true);
		setLoadVideo(false);
	   }
   }

	const playerReference = React.useRef(null);

	// All sources, in an actual case will be Media, OCMedia and VOD of message.
	let sources = [
		'https://media.auth.gr/ScheduleRecording/' + videoLink + "/playlist.m3u8",	 // Media
		'https://ocmedia.auth.gr/OpenCourses/' + videoLink + "/playlist.m3u8",	 // Media
		'https://media.auth.gr/vod/acsup-offline-GR.mp4/playlist.m3u8' // Message
	];

	 const videoJsOptions = {
		autoplay: true,
		controls: false,
		responsive: true,
		fluid: true,
		muted: true,
		sources: [
		  {
			src: ((liveVideo == true) ? sources[0] : "https://media.auth.gr:443/vod/_definst_/" + videoLink + "/playlist.m3u8"),
			type: "application/x-mpegurl",
		  },
		],
	  };
	  
	  const playerReady = (player) => {
		playerReference.current = player;
		player.on("waiting", () => {
		  //console.log("Video Player is waiting");
		});
		player.on("dispose", () => {
		  //console.log("Video player will dispose");
		});
		
		if (props.video_live == '1') {
			var currentIndex = 0;
			player.src(sources[currentIndex]);
			player.on(['error'], function() {
				if (currentIndex < sources.length - 1 || !player.buffered().end(0)) {
					currentIndex++;
					player.src(sources[currentIndex]);
				} else {
					// handle error when all sources fail
					//console.log('All sources failed');
				}
			});
		} else {
			player.on("play", () => {
				player.currentTime(10);
				//player.duration();
			});
		}
	};

	let newVideoCategory;
	if( props.video_category.toString().includes("vc") ) {
		newVideoCategory = props.video_category.split("=")[1];
	} else {
		newVideoCategory = props.video_category;
	}
	
  return (
    <div className="item-video">
		<div className="item-hover" onMouseOver={loadSmallVideo} onMouseOut={loadCoverImage}>	
      		<div className="uk-inline">
	  
				{ coverImage && 
					<div className="cover-img">
						<img src={props.video_image} alt="" />
					</div>
				}
		
				{ loadVideo &&
					<div className="video-play">
						<VideoJS options={videoJsOptions} onReady={playerReady}/> 
					</div>
				}

				<div className="uk-overlay-default uk-position-cover"></div>
				<Link to={props.video_url} className="video-link">
					<FontAwesomeIcon icon={faPlayCircle} className="video-play-icon" />
					<span className="uk-hidden">
						{props.video_title.substring(0, 70)}
					</span>
				</Link>

        		<div className="video-meta">
					{newVideoCategory != "" && (
						<span className="video-category"> <Link to={`${props.video_category}`}>{props.video_category_label}</Link></span>
					)}
					
					{(props.video_live == 1 &&  props.live_until != "" && currentDate < props.live_until) && (
						<span className="video-live">
						<FontAwesomeIcon icon={faVideo} /> LIVE
						</span> 
					)}
				</div>
     	 	</div>

			<div className="bottom-info">
				<h4 className="video-title">
				<Link to={props.video_url}>
					{
					parser.parseFromString(`${props.video_title}`, "text/html").body
						.textContent
					}
				</Link>
				</h4>

				<div className="video-metadata">
					<span className="video-date">
						<FontAwesomeIcon icon={faCalendarDays} className="calendar-icon" />
						<ConvertDate postDate={videoDate} />
					</span>
				</div>
      		</div>
	   </div>
    </div>
  );
};

export default ItemVideo;