import React, { useState, useEffect } from "react";
import "./css/Pagination.css";
import useSearchParamsState from "../hooks/useSearchParamsState"; //custom hook για να διατηρούμε τα ορίσματα στο url

import {
  useParams,
  useLocation,
  useNavigate, useSearchParams,
} from "react-router-dom";

function pageAlgo(currentPage, pageCount) {
  let delta = 2,
    left = currentPage - delta,
    right = currentPage + delta + 1,
    result = [];

  result = Array.from({ length: pageCount }, (v, k) => k + 1).filter(
    i => i && i >= left && i < right
  );

  if (result.length > 1) {
    // Add first page and dots
    if (result[0] > 1) {
      if (result[0] > 2) {
        result.unshift("...");
      }
      result.unshift(1);
    }

    // Add dots and last page
    if (result[result.length - 1] < pageCount) {
      if (result[result.length - 1] !== pageCount - 1) {
        result.push("...");
      }
      result.push(pageCount);
    }
  }

  return result;
}

const Pagination = ({ nrOfPages, currentpage, onSelectPage }) => {
  const [pagination, setPagination] = useState([]);
  const [pageParam, setPageParam] = useSearchParamsState("page", 1);
  //Αποθηκεύουμε την παράμετρο απο το url
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const pageNumber = params.get("page");

  useEffect(() => {
    const paginationArray = pageAlgo(
      parseInt(currentpage, 0),
      parseInt(nrOfPages, 0)
    );
    setPagination(paginationArray);
  }, [currentpage, nrOfPages]);

  let c;
  if(!pageNumber) {
      c = 1;
  } else {
    c = pageNumber;
  }

  return (
    <div className="posts-app__pagination">
      {pagination.map((n, i) => {
        return n != "..." ? (
          <button className={`${n==c ? 'active-page' : ''}`}
            key={i} 
            onClick={() => {
              onSelectPage(n);
              setPageParam(n); 
            }}
          >
            {n}
          </button>
        ) : (
          <button key={i}>...</button>
        );
      })}
    </div>
  );
};

export default Pagination;