import React, { useState, useEffect, useCallback } from "react";
import ItemVideoList from "./ItemVideoList";
import noVideo from "../images/video-poster.png";

const MoreUnitVideos = (props) => {
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        let unitID = "";
        if(props.units) {
            unitID = props.units.map((unit) => (
                 unit.ID
            )).toString();
            
            const delayDebounceFn = setTimeout(() => {
              fetch('https://auth.gr/wp-json/wp/v2/posts?categories=322&auth_units=' + unitID + '&_embed&per_page=5')
              .then((response) => {
              return response.json()
              })
              .then((data) => {
                  setVideos(data);
              
              });
            }, 1000);

            return () => clearTimeout(delayDebounceFn);
        }
    },[props.units]);

    return (
        <React.Fragment>
            {videos.length != 0 ? (
             videos.map((video) => (
               <ItemVideoList
                 id={video.id}
                 key={video.id}
                 video_category={`/browse?vc=${video.acf.video_category ? video.acf.video_category.value : ''}`}
                 video_category_label={video.acf.video_category ? video.acf.video_category.label : ''}
                 video_date={video.date}
                 video_image={
                   video._embedded["wp:featuredmedia"]
                     ? video._embedded["wp:featuredmedia"][0].source_url
                     : noVideo
                 }
                 video_title={video.title.rendered}
                 video_url={`/video/${video.id}`}
                 video_live={video.acf.video_live}
                 live_until={video.acf.live_until} //Y-m-d G:i:s
               />
             ))
           ) : (
             <div type="no-results-msg">Δεν βρέθηκαν βίντεο</div>
           )}
        </React.Fragment>
    );
}

export default MoreUnitVideos;