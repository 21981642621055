import { useState, useEffect, useCallback } from "react";

/* Custom hook useFetchViews
 * Ένα custom hook μπορεί να χρησιμοποιεί react hooks και State logic.
 * Υποχρεωτικά το όνομα της function πρέπει να ξεκινάει με use.
 */

const useFetchViews = (requestConfig, applyData) => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  /* useCallback allows us to isolate resource intensive functions so that they will not automatically run on every render.
   * Χωρίς την useCallback το fetchVideosHandler θα τρέχει συνέχεια
   */
  const fetchVideosHandler = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(requestConfig.url, {
        method: requestConfig.method ? requestConfig.method : "POST",
        headers: requestConfig.headers ? requestConfig.headers : {},
        body: requestConfig.body ? JSON.stringify(requestConfig.body) : null,
      });

      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const data = await response.json();

      applyData(data);
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  return {
    isLoading,
    error,
    fetchVideosHandler,
  };
};

export default useFetchViews;