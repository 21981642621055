import React, { useState, useEffect, useCallback } from "react";
import "./css/VideoViews.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import useFetchVideos from "../hooks/use-fetch-videos";

const VideoViews = (props) => {
  const postID = props.videoID;

  const [views, setViews] = useState([]);

  const saveViews = useCallback((viewsObj) => {
    setViews(viewsObj);
  }, []);

  const {
    isLoading,
    error,
    fetchVideosHandler: fetchViews,
  } = useFetchVideos(
    {
      url:
        "https://auth.gr/wp-json/post-views-counter/get-post-views/" +
        postID,
    },
    saveViews
  );

  useEffect(() => {
    fetchViews();
  }, [fetchViews]);

  return (
    <span className="video-views">
      <FontAwesomeIcon icon={faEye} className="views-icon" />
      <span>{views} προβολές</span>
    </span>
  );
};

export default VideoViews;