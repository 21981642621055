import React,{useState,useCallback,useEffect} from "react";
import { Link } from "react-router-dom";
import "./css/ItemVideoList.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo, faCalendarDays, faTags } from "@fortawesome/free-solid-svg-icons";
import ConvertDate from "./ConvertDate";
import VideoViews from "./VideoViews";
import VideoJS from "./VideoJS";
import useFetchVideos from "../hooks/use-fetch-videos";
import Moment from "moment";

const ItemVideoList = (props) => {
  const [isMobile, setIsMobile] = useState(false)
  const videoDate = props.video_date.split("T")[0];
  const[coverImage,setCoverImage] = useState(true);
  const[loadVideo,setLoadVideo] = useState(false);
  const [video, setVideo] = useState([]);
  const id = props.id;
  const parser = new DOMParser();
  const currentDate = Moment().format("YYYY-MM-DD HH:mm:ss");
  let liveVideo,videoLink,videoUnits = "";	
  let newVideoCategory;
  
  if( props.video_category.toString().includes("vc") ) {
	    newVideoCategory = props.video_category.split("=")[1];
    } else {
	    newVideoCategory = props.video_category;
    }
	
  return (
    <div className="item-video-list">
        <div className="uk-grid uk-margin-bottom">
            <div className="uk-width-1-1 uk-width-1-2@s uk-width-1-3@m">
                { coverImage && 
                <div className="cover-img">
                    <Link to={props.video_url}><img src={props.video_image} alt={props.video_title} className="more-videos-image"/></Link>
                </div>
                }
            </div>

            <div className="uk-width-1-1 uk-width-1-2@s uk-width-2-3@m">
                <div className="more-video-meta">
                    <h4 className="video-title">
                        <Link to={props.video_url} title={props.video_title}>
                            {
                            parser.parseFromString(`${props.video_title.length > 120 ? props.video_title.substring(0, 120)+'...' : props.video_title}`, "text/html").body
                                .textContent
                            }
                        </Link>
                    </h4>
                    
                    <div className="block-box">
                        <span className="video-date">
                            <FontAwesomeIcon icon={faCalendarDays} className="calendar-icon" />
                            <ConvertDate postDate={videoDate} />
                        </span>
                            
                        <span className="video-category">
                            {newVideoCategory != "" && (
                                <div>
                                    <FontAwesomeIcon icon={faTags} className="calendar-icon" />
                                    <span className="video-category"> <Link to={`${props.video_category}`}>{props.video_category_label}</Link></span>
                                </div>
                            )}  
                        </span>
                        
                        {(props.video_live == 1 &&  props.live_until != "" && currentDate < props.live_until) && (
                            <span className="video-live">
                            <FontAwesomeIcon icon={faVideo} /> LIVE
                            </span> 
                        )}
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default ItemVideoList;