import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useFetchVideos from "../hooks/use-fetch-videos";
import "./css/UnitVideos.css";

const UnitVideos = (props) => {
  const [videos, setVideos] = useState([]);
  const fetchUrl = props.fetchUrl;
  const units = require.context("../images/units", true);

  const saveVideos = (videosObj) => {
    const loadedVideos = [];

    for (const videoKey in videosObj) {
      loadedVideos.push({
        id: videosObj[videoKey].id,
        title: videosObj[videoKey].unit,
        countVideos: videosObj[videoKey].count_videos,
      });
    }
    setVideos(loadedVideos);
  };

  /* Καλούμε το custom hook useFetchVideos και στέλνουμε 2 ορίσματα. Το πρώτο είναι το url απο το endpoint που θέλουμε
   * να διαβάσουμε και το 2ο είναι η συνάρτηση saveVideos την οποία υλοποιούμε παραπάνω.
   * Το custom hook επιστρέφει τρία στοιχεία. Το isLoading, error και fetchVideosHandler. Τα αποθηκεύω και αλλάζω το
   * όνομα του fetchVideosHandler σε fetchVideos
   */
  const {
    isLoading,
    error,
    fetchVideosHandler: fetchVideos,
  } = useFetchVideos(
    {
      url: fetchUrl,
    },
    saveVideos
  );

  useEffect(() => {
    fetchVideos();
  }, [fetchVideos]);

  return (
    <React.Fragment>
      {videos.length != 0 && (
        <div className="unit-videos">
          <div className="carousel-data">
            <h3>
              {props.title}
              <Link className="view-more-units" to="/units">Προβολή όλων</Link>
            </h3>
                <ul className="uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-2@m uk-child-width-1-6@l uk-grid uk-grid-small uk-grid-match">
                  {videos.slice(0,6).map((video) => (
                    <div key={video.id} className="uk-margin-bottom">
                      <div className="details-box">
                        <h4>
                          {" "}
                          <Link to={`/browse?unit=${video.id}`}>
                            {video.title}
                          </Link>
                        </h4>
                        <span className="count-videos">
                          {video.countVideos} βίντεο
                        </span>
                      </div>
                    </div>
                  ))}
                </ul>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default UnitVideos;