import React, { useState, useEffect, useCallback } from "react";
import useFetchViews from "../hooks/use-fetch-views";

const UpdateViewsCounter = (props) => {
  const postID = props.videoID;
  const [updateStatus, setUpdateStatus] = useState(false);

  const updateViews = useCallback((viewsObj) => {
    setUpdateStatus(true);
  }, []);

  const {
    isLoading,
    error,
    fetchVideosHandler: fetchStatus,
  } = useFetchViews(
    {
      url:
        "https://auth.gr/wp-json/post-views-counter/view-post?id=" +
        postID,
    },
    updateViews
  );

  useEffect(() => {
    fetchStatus();
  }, [fetchStatus]);

  return <React.Fragment></React.Fragment>;
};

export default UpdateViewsCounter;