import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useFetchVideos from "../hooks/use-fetch-videos";
import noVideo from "../images/no-video.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo, faCalendarDays, faFire  } from "@fortawesome/free-solid-svg-icons";
import ConvertDate from "./ConvertDate";

const LatestVideo = (props) => {
	const parser = new DOMParser();
	const [latestVideo, setLatestVideo] = useState([]);
	const saveLatestVideo = (videosObj) => {
    const loadedVideo = [];

    for (const videoKey in videosObj) {
      let sourceUrl = ""; 
      if (videosObj[videoKey]._embedded.hasOwnProperty("wp:featuredmedia")) {
        const urls = videosObj[videoKey]._embedded["wp:featuredmedia"].map(
          (videoItem) => videoItem
        );
        sourceUrl = urls[0].source_url;
      }
      loadedVideo.push({
        id: videosObj[videoKey].id,
        title: videosObj[videoKey].title.rendered,
        image: sourceUrl ? sourceUrl : noVideo,
        videoCategory: videosObj[videoKey].acf.video_category.value,
        videoCategoryLabel: videosObj[videoKey].acf.video_category.label,
        videoDate: videosObj[videoKey].date.split("T")[0],
      });
    }
    setLatestVideo(loadedVideo);
  };

  const {
    isLoading,
    error,
    fetchVideosHandler: fetchLatestVideo,
  } = useFetchVideos(
    {
      url: "https://auth.gr/wp-json/wp/v2/posts?categories=322&live=0&per_page=1&_embed",
    },
    saveLatestVideo
  );

  useEffect(() => {
    fetchLatestVideo();
  }, [fetchLatestVideo]);

  return (
    <React.Fragment>
      {latestVideo.map((video) => (
        <div key={video.id} className={`sticky-post ${video.videoCategory}`}>
          <div className="uk-grid">
            <div className="uk-width-1-1 uk-width-2-3@m">
				<div className="suggested-video-box"> 
					<FontAwesomeIcon icon={faFire} className="fire-icon"/>
					Προτεινόμενο βίντεο
				</div>
              <h4>
                <Link to={`/video/${video.id}`} className="video-link">
				          { parser.parseFromString(`${video.title}`, "text/html").body.textContent }
				        </Link>
              </h4>
              <p>
                <FontAwesomeIcon icon={faCalendarDays} className="calendar-icon" />
                <ConvertDate postDate={video.videoDate} />
              </p>
            </div>
            <div className="uk-width-1-1 uk-width-1-3@m">
              <Link to={`/video/${video.id}`} className="video-link">
                <img src={video.image} alt={video.title} />
              </Link>
            </div>
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};

export default LatestVideo;