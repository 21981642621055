import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import "./css/Pages.css";

const NoResultsMessage = (props) => {
    return (
        <div className="no-results-msg"> 
            <FontAwesomeIcon icon={faCircleInfo} className="info-icon" /> 
               {props.message}
        </div>
    );
}

export default NoResultsMessage;