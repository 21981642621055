import React from "react";
import "./css/Spinner.css";

const Spinner = () => {
    return (
        <React.Fragment>
            <div className="spinner-container">
                <div className="loading-spinner">
                </div>
            </div>
        </React.Fragment>
    );
};

export default Spinner;