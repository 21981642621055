import React from "react";
import "./css/Footer.css";
import { Link  } from 'react-router-dom';

const Footer = () => {
    return (
        <div id="footer">
           <div className="uk-grid" data-uk-grid>
                <div className="uk-width-small uk-visible@m"></div>

                <div className="uk-width-expand">
                    <div className="copyright-meta">
                        <span className="copyright-year">Copyright 2023 </span> 
                        <span className="copyright-unit">
                        <Link to="https://auth.gr/" target="_blank">
                            Αριστοτέλειο Πανεπιστήμιο Θεσσαλονίκης
                        </Link>
                           
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;