import React from "react";
import { Link } from "react-router-dom";
import "./css/VideoCategories.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuildingColumns, faCalendarDays, faBusinessTime, faPersonChalkboard, faGraduationCap } from "@fortawesome/free-solid-svg-icons";

const VideoCategories = (props) => {
  return (
    <React.Fragment>
      <div className="videos-categories">
        <div className="uk-grid-small" data-uk-grid>
          <div className="uk-width-1-1 uk-width-1-6@m uk-width-1-2@s">
            <div className="details-box">
              <div className="icon-box1">
                <FontAwesomeIcon icon={faBuildingColumns} />
              </div>
              <h4>
                <Link to={`/browse?vc=rectory`}>
                  Πρυτανικές Αρχές
                </Link>
              </h4>
            </div>
          </div>

          <div className="uk-width-1-1 uk-width-1-6@m uk-width-1-2@s">
            <div className="details-box">
              <div className="icon-box2">
                <FontAwesomeIcon icon={faCalendarDays} />
              </div>
              <h4>
                <Link to={`/browse?vc=events`}>Εκδηλώσεις </Link>
              </h4>
            </div>
          </div>

          <div className="uk-width-1-1 uk-width-1-6@m uk-width-1-2@s">
            <div className="details-box">
              <div className="icon-box3">
                <FontAwesomeIcon icon={faBusinessTime} />
              </div>
              <h4>
                <Link to={`/browse?vc=meetings`}>Ημερίδες</Link>
              </h4>
            </div>
          </div>

          <div className="uk-width-1-1 uk-width-1-6@m uk-width-1-2@s">
            <div className="details-box">
              <div className="icon-box4">
                <FontAwesomeIcon icon={faPersonChalkboard} />
              </div>
              <h4>
                <Link to={`/browse?vc=conference`}>Συνέδρια</Link>
              </h4>
            </div>
          </div>

          <div className="uk-width-1-1 uk-width-1-6@m uk-width-1-2@s">
            <div className="details-box">
              <div className="icon-box5">
                <FontAwesomeIcon icon={faGraduationCap} />
              </div>
              <h4>
                <Link to={`/browse?vc=orkomosia`}>Ορκωμοσίες</Link>
              </h4>
            </div>
          </div>

          <div className="uk-width-1-1 uk-width-1-6@m uk-width-1-2@s">
            <div className="details-box">
              <div className="icon-box6">
                <FontAwesomeIcon icon={faBuildingColumns} />
              </div>
              <h4>
                <Link to={`/browse?vc=workshops`}>Workshops</Link>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VideoCategories;