import React, { useState, useEffect } from "react";
import "../css/Pages.css";
import { Link } from "react-router-dom";
import Navigation from "../Navigation";
import useFetchVideos from "../../hooks/use-fetch-videos";
import Footer from "../Footer";
import NoResultsMessage from "../NoResultsMessage";
import Spinner from "../Spinner";

const Units = (props) => {
  const [videos, setVideos] = useState([]);
  const units = require.context("../../images/units", true);
  const saveVideos = (videosObj) => {
    const loadedVideos = [];
    for (const videoKey in videosObj) {
      loadedVideos.push({
        id: videosObj[videoKey].id,
        title: videosObj[videoKey].unit,
        countVideos: videosObj[videoKey].count_videos,
      });
    }
    setVideos(loadedVideos);
  };

  const {
    isLoading,
    error,
    fetchVideosHandler: fetchVideos,
  } = useFetchVideos(
    {
      url: "https://auth.gr/wp-json/wp/v2/unitvideos",
    },
    saveVideos
  );

  useEffect(() => {
    fetchVideos();
    document.title = 'Μονάδες ΑΠΘ - audiovisual.auth';
  }, [fetchVideos]);

  return (
    <React.Fragment>
      <div className="uk-grid" data-uk-grid>
        <div className="uk-width-small uk-visible@m">
          <Navigation />
        </div>
        <div className="uk-width-expand">
          <h2>Υλικό ανά μονάδα</h2>

          {videos.length != 0 ? (
            <div className="videos-per-unit">
              <div className="uk-grid uk-grid-match uk-grid-small" data-uk-grid>
                {videos.map((video) => (
                  <div className="uk-width-1-1 uk-width-1-2@s uk-width-1-6@m" key={video.id}>
                    <div className="details-box">
                      <h4>
                        {" "}
                        <Link to={`/browse?unit=${video.id}`}>
                          {video.title}
                        </Link>
                      </h4>
                      <span className="count-videos">
                        {video.countVideos} βίντεο
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )
        :
        <div>
           { isLoading ? <Spinner /> : <NoResultsMessage message="Δεν βρέθηκαν μονάδες"/> } 
        </div>
        }
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};
export default Units;