import React, { useState, useEffect, useCallback } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "../node_modules/uikit/dist/css/uikit.css";
import "../node_modules/uikit/dist/js/uikit.min.js";
import Header from "./components/Header";
import Home from "./components/pages/Home";
import Units from "./components/pages/Units";
import Today from "./components/pages/Today";
import Categories from "./components/pages/Categories";
import VideoDetails from "./components/pages/VideoDetails";
import "./App.css";
import "./components/css/Responsive.css";
import "./components/css/Lightmode.css";
import Browse from "./components/pages/Browse";

//every object represents one Route
const router = createBrowserRouter([
  { path: "/", element: <Home /> },
  { path: "/browse?type=videos", element: <Browse /> },
  { path: "/browse?type=live-streaming", element: <Browse /> },
  { path: "/units", element: <Units /> },
  { path: "/browse?year=2023", element: <Browse /> },
  { path: "/today", element: <Today /> },
  { path: "/categories", element: <Categories /> },
  { path: "/video/:id", element: <VideoDetails /> },
  { path: "/browse", element: <Browse /> },
]);

function App() {
  return (
    <React.Fragment>
      <Header />
      <div className="main-content">
        <RouterProvider router={router} />
      </div>
    </React.Fragment>
  );
}
export default App;