import React, { useState, useEffect } from "react";
import useFetchVideos from "../hooks/use-fetch-videos";
import Select, { useCreatable } from 'react-select/creatable';

const CreateUnitsSelect = (props) => {
  const [videos, setVideos] = useState([]);

  const saveVideos = (videosObj) => {
    const loadedVideos = [];

    for (const videoKey in videosObj) {
      loadedVideos.push({
        id: videosObj[videoKey].id,
        title: videosObj[videoKey].unit,
      });
    }
    setVideos(loadedVideos);
  };

  const {
    isLoading,
    error,
    fetchVideosHandler: fetchVideos,
  } = useFetchVideos(
    {
      url: "https://auth.gr/wp-json/wp/v2/unitvideos",
    },
    saveVideos
  );

  useEffect(() => {
    fetchVideos();
  }, [fetchVideos]);

	const unitsOptionArray = [{ value: 'all', label: 'ΟΛΑ' }];

	videos.map((video) => (
		unitsOptionArray.push({ value: video.id.toString(), label: video.title.toString() },)      
  ))

	const checkdefault = (OptionArray, SelectedValue) => {
		let itemLabel="";
		OptionArray.map((item) => {
			if(item.value == SelectedValue) {
				itemLabel = item.label;
			}
		});
		
		return { label: itemLabel, value: SelectedValue };
	};         

  return (
    <>
      <label htmlFor="video-unit-field" className="uk-hidden"> Μονάδα </label>
      <Select 
        options={unitsOptionArray} 
        inputId="video-unit-field"
        name="unit"
        onChange={props.filter}
        value={props.unitProp == null ? { label: "Επιλέξτε μονάδα...", value: "all" } : checkdefault(unitsOptionArray, props.unitProp) }
      />
    </>
  );
};

export default CreateUnitsSelect;