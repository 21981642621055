import React from "react";
import { Link, useLocationm, BrowserRouter, useLocation  } from "react-router-dom";
import "./css/NavigationOffCanvas.css";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBars,
    faHouseChimney,
    faPlay,
    faVideo,
    faLayerGroup,
    faBuilding,
    faCalendarDays,
    faClock,
    faTags,
    faClockRotateLeft,
} from "@fortawesome/free-solid-svg-icons";

const OffCanvas = (props) => {
    const path = useLocation();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const type = params.get("type");
    const year = params.get("year");

    return (
        <div id="offcanvas-usage" data-uk-offcanvas>
            <div className="uk-offcanvas-bar">
                <button className="uk-offcanvas-close" type="button" data-uk-close></button>
                <ul className="uk-nav uk-nav-primary uk-nav-left uk-margin-auto-vertical">
                    <li>
                        <a href="/" aria-label="home" className="uk-margin-small-left">
                            <FontAwesomeIcon icon={faHouseChimney} className="off-canvas-icon" />
                            <span>Αρχική</span>
                        </a>    
                    </li>

                    <li>
                        <a href="/browse" aria-label="browse" className={`uk-margin-small-left nav-menu-icon ${((path.pathname == '/browse') && (!type) && (!year)) ? 'uk-active' : ''} `}>
                            <FontAwesomeIcon icon={faLayerGroup}  className="off-canvas-icon"/> 
                            <span>Βιβλιοθήκη</span>
                        </a>    
                    </li>

                    <li>
                        <a href="/browse?type=videos" aria-label="videos" className={`uk-margin-small-left nav-menu-icon ${type == 'videos' ? 'uk-active' : ''} `}>
                            <FontAwesomeIcon icon={faPlay}  className="off-canvas-icon"/>
                            <span>Βίντεο</span>
                        </a>    
                    </li>
                   
                    <li>
                        <a href="/browse?type=live-streaming" aria-label="live-streaming" className={`uk-margin-small-left nav-menu-icon ${type == 'live-streaming' ? 'uk-active' : ''} `}>
                            <FontAwesomeIcon icon={faVideo}  className="off-canvas-icon"/>
                            <span>Live Streaming</span>
                        </a>    
                    </li>

                    <li>
                        <a href="/units" aria-label="units" className={`uk-margin-small-left nav-menu-icon ${path.pathname == '/units' ? 'uk-active' : ''} `}>
                            <FontAwesomeIcon icon={faBuilding}  className="off-canvas-icon"/>
                            <span>Υλικό ανά μονάδα</span>
                        </a>    
                    </li>

                    <li>
                        <a href="/browse?year=2023" aria-label="year" className={`uk-margin-small-left nav-menu-icon ${(year && year != 'all') ? 'uk-active' : ''} `}>
                            <FontAwesomeIcon icon={faCalendarDays}  className="off-canvas-icon"/>
                            <span>Υλικό ανά έτος</span>
                        </a>    
                    </li>
                   
                    <li>
                        <a href="/today" aria-label="today" className={`uk-margin-small-left nav-menu-icon ${path.pathname == '/today' ? 'uk-active' : ''} `}>
                            <FontAwesomeIcon icon={faClock}  className="off-canvas-icon"/>
                            <span>Προστέθηκαν σήμερα</span>
                        </a>    
                    </li>
                  
                    <li>
                        <a href="/categories" aria-label="categories" className={`uk-margin-small-left nav-menu-icon ${path.pathname == '/categories' ? 'uk-active' : ''} `}>
                            <FontAwesomeIcon icon={faTags}  className="off-canvas-icon"/>
                            <span>Κατηγορίες</span>
                        </a>    
                    </li>
                </ul>
            </div>
        </div>
    );
}

const NavigationOffCanvas = (props) => {
  return (
    <React.Fragment>
        <a href="#offcanvas-usage" data-uk-toggle="target: #offcanvas-usage" className="burger-menu">
            <FontAwesomeIcon icon={faBars} />
        </a>
        {ReactDOM.createPortal(<OffCanvas/>, document.getElementById('offcanvas-root'))}
     </React.Fragment>
  );
};
export default NavigationOffCanvas;