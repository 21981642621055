import React, { useState, useEffect } from "react";
import useFetchVideos from "../hooks/use-fetch-videos";
import noVideo from "../images/no-video.png";
import { Link } from "react-router-dom";
import "./css/LiveNow.css";
import NoResultsMessage from "./NoResultsMessage";

const LiveNow = (props) => {
  const [liveVideos, setLiveVideos] = useState([]);
  const parser = new DOMParser();
  const saveLiveVideos = (videosObj) => {
    const loadedVideos = [];

    for (const videoKey in videosObj) {
      let sourceUrl = ""; 
      if (videosObj[videoKey]._embedded.hasOwnProperty("wp:featuredmedia")) {
        const urls = videosObj[videoKey]._embedded["wp:featuredmedia"].map(
          (videoItem) => videoItem
        );
        sourceUrl = urls[0].source_url;
      }
      loadedVideos.push({
        id: videosObj[videoKey].id,
        title: videosObj[videoKey].title.rendered,
        image: sourceUrl ? sourceUrl : noVideo,
      });
    }
    setLiveVideos(loadedVideos);
  };

  const {
    isLoading,
    error,
    fetchVideosHandler: fetchLiveVideos,
  } = useFetchVideos(
    {
      url: "https://auth.gr/wp-json/wp/v2/posts?categories=322&live=1&_embed&per_page=3",
    },
    saveLiveVideos
  );

  useEffect(() => {
    fetchLiveVideos();
  }, [fetchLiveVideos]);

  return (
    <React.Fragment>
      <div className="details-box">
        <div className="flex-elements">
          <h3>LIVE STREAMING</h3>
          <Link to="/browse?type=live-streaming" className="view-more">
            ΠΡΟΒΟΛΗ ΟΛΩΝ
          </Link>
        </div>
        <div className="live-list">
          {liveVideos.length != 0 
          ? (liveVideos.map((video) => (
              <div className="uk-grid" key={video.id} data-uk-grid>
                <div className="uk-width-1-1 uk-width-1-3@m">
                  <Link to={`/video/${video.id}`} className="live-image-link">
                    <img src={video.image} />
                  </Link>
                  <div className="live-badge">LIVE</div>
                </div>
                <div className="uk-width-1-1 uk-width-2-3@m">
                  <Link to={`/video/${video.id}`} className="live-title-link"> {parser.parseFromString(`${video.title}`, "text/html").body.textContent}</Link>
                </div>
              </div>
            ))
         ) : (
          <NoResultsMessage message="Αυτή τη στιγμή δεν υπάρχει προγραμματισμένη live μετάδοση"/>
         )
          }
        </div>
      </div>
    </React.Fragment>
  );
};

export default LiveNow;