import React, { useState, useEffect } from "react";
import useFetchVideos from "../hooks/use-fetch-videos";
import ItemVideo from "./ItemVideo";
import noVideo from "../images/video-poster.png";
import "./css/Carousel.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeftLong, faRightLong } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
const Carousel = (props) => {
  const [videos, setVideos] = useState([]);
  const fetchUrl = props.fetchUrl;
  /* Η συνάρτηση saveVideos στέλνεται σαν 2 όρισμα στο custom hook 'use-fetch-videos'.
   * Παίρνει σαν όρισμα τα video Objects τα οποία είναι τα data που μας φέρνει το fetch απο το endpoint του wordpress
   * και τα αποθηκεύει με τη useState στο videos
   */

  const saveVideos = (videosObj) => {
    const loadedVideos = [];

    for (const videoKey in videosObj) {
      let sourceUrl = "";
      if (videosObj[videoKey]._embedded.hasOwnProperty("wp:featuredmedia")) {
        const urls = videosObj[videoKey]._embedded["wp:featuredmedia"].map(
          (videoItem) => videoItem
        );
        sourceUrl = urls[0].source_url;
      }

      loadedVideos.push({
        id: videosObj[videoKey].id,
        text: videosObj[videoKey].title.rendered,
        image: sourceUrl ? sourceUrl : noVideo,
        videoCategory: videosObj[videoKey].acf.video_category.value,
        videoCategoryLabel: videosObj[videoKey].acf.video_category.label,
        videoDate: videosObj[videoKey].date,
        videoLive: videosObj[videoKey].acf.video_live,
      });
    }
    setVideos(loadedVideos);
  };

  /* Καλούμε το custom hook useFetchVideos και στέλνουμε 2 ορίσματα. Το πρώτο είναι το url απο το endpoint που θέλουμε
   * να διαβάσουμε και το 2ο είναι η συνάρτηση saveVideos την οποία υλοποιούμε παραπάνω.
   * Το custom hook επιστρέφει τρία στοιχεία. Το isLoading, error και fetchVideosHandler. Τα αποθηκεύω και αλλάζω το
   * όνομα του fetchVideosHandler σε fetchVideos
   */
  const {
    isLoading,
    error,
    fetchVideosHandler: fetchVideos,
  } = useFetchVideos(
    {
      url: fetchUrl,
    },
    saveVideos
  );

  useEffect(() => {
    fetchVideos();
  }, [fetchVideos]);

  return (
    <React.Fragment>
      {videos.length != 0 && (
        <div className="carousel-data">
          <h3>
            {props.title}
            {props.category == "categories" &&
              <Link className="view-more-categories" to={`/browse?vc=${props.categoryValue}`}>Προβολή όλων</Link>
            }
          </h3>
         
          <div data-uk-slider>
            <div className="uk-position-relative">
              <div className="uk-slider-container uk-light">
                <ul className="uk-slider-items uk-child-width-1-1 uk-child-width-1-2@m uk-child-width-1-2@s uk-child-width-1-3@l uk-child-width-1-4@xl uk-grid">
                  {videos.map((video) => (
                    <li key={video.id}>
                      <ItemVideo
                        key={video.id}
                        id={video.id}
                        video_image={video.image}
                        video_category={`/browse/?vc=${video.videoCategory}`}
                        video_category_label={video.videoCategoryLabel}
                        video_date={video.videoDate}
                        video_title={video.text}
                        video_url={`/video/${video.id}`}
                        video_live={video.videoLive}
                      />
                    </li>
                  ))}
                </ul>
              </div>

              <div className="uk-hidden@s uk-light">
                <a className="previous-arrow" href="#" data-uk-slidenav-previous data-uk-slider-item="previous">
                  <FontAwesomeIcon icon={faLeftLong} />
                </a>
                <a className="next-arrow" href="#" data-uk-slidenav-next data-uk-slider-item="next">
                  <FontAwesomeIcon icon={faRightLong} />
                </a>
              </div>

              <div className="uk-visible@s">
                <a className="previous-arrow" href="#" data-uk-slidenav-previous data-uk-slider-item="previous">
                  <FontAwesomeIcon icon={faLeftLong} />
                </a>
                <a className="next-arrow " href="#" data-uk-slidenav-next data-uk-slider-item="next">
                  <FontAwesomeIcon icon={faRightLong} />
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Carousel;