import React, { useState, useEffect } from "react";
import Navigation from "../Navigation";
import Carousel from "../Carousel";
import useFetchVideos from "../../hooks/use-fetch-videos";
import LatestVideo from "../LatestVideo";
import LiveNow from "../LiveNow";
import VideoCategories from "../VideoCategories";
import UnitVideos from "../UnitVideos";
import Moment from "moment";
import "moment/locale/el";
import Footer from "../Footer";

const Home = () => {
  const [videosIDs, setVideoIDs] = useState([]);
  const saveVideoIDs = (videosObj) => {
    setVideoIDs(videosObj);
  };

  const {
    isLoading,
    error,
    fetchVideosHandler: fetchVideoIDs,
  } = useFetchVideos(
    {
      url: "https://auth.gr/wp-json/wp/v2/monthlypostviews",
    },
    saveVideoIDs
  );

  useEffect(() => {
    fetchVideoIDs();
    document.title = 'audiovisual.auth';
  }, [fetchVideoIDs]);

  const popularVideos = videosIDs.toString();

  Moment.locale("el");
  const months = ['', 'Ιανουάριο', 'Φεβρουάριο', 'Μάρτιο', 'Απρίλιο', 'Μάϊο', 'Ιούνιο', 'Ιούλιο', 'Αύγουστο', 'Σεπτέμβριο', 'Οκτώβριο', 'Νοέμβριο', 'Δεκέμβριο'];
  const currentMonth = Moment().format("M"); 

  return (
    <div className="homepage">
      <div className="uk-grid" data-uk-grid>
        <div className="uk-width-small uk-visible@m">
          <Navigation />
        </div>
        <div className="uk-width-expand">
          <div className="uk-grid" data-uk-grid>
            <div className="uk-width-1-1 uk-width-2-3@m">
              <LatestVideo />
            </div>
            <div className="uk-width-1-1 uk-width-1-3@m">
              <LiveNow />
            </div>
          </div>
          <Carousel
            title="Προστέθηκαν πρόσφατα"
            fetchUrl="https://auth.gr/wp-json/wp/v2/posts?categories=322&live=0&_embed&per_page=12"
          />
          {videosIDs.length > 1 && (
            <Carousel
              title={`Δημοφιλή βίντεο για το μήνα ${months[currentMonth]}`}
              fetchUrl={`https://auth.gr/wp-json/wp/v2/posts?categories=322&include=${popularVideos}&live=0&_embed&per_page=12&orderby=include`}
            />
          )}

          <h3>Κατηγοριες</h3>
          <VideoCategories />
          <UnitVideos title="Ανά μονάδα ΑΠΘ" fetchUrl="https://auth.gr/wp-json/wp/v2/unitvideos"/>
        </div>
      </div>
     <Footer />
    </div>
  );
};
export default Home;