import React from "react";
import "./css/Navigation.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation  } from 'react-router-dom';
import {
  faHouseChimney,
  faPlay,
  faVideo,
  faLayerGroup,
  faBuilding,
  faCalendarDays,
  faClock,
  faTags,
  faClockRotateLeft,
} from "@fortawesome/free-solid-svg-icons";

const Navigation = (props) => {
  const path = useLocation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const type = params.get("type");
  const year = params.get("year");

  return (
    <div id="navigation-menu">
      <div className="menu">
      <Link to="/" aria-label="home" className="home-btn">
        <FontAwesomeIcon icon={faHouseChimney} />
      </Link>
      <div className="menu-nav">
        <Link to="/browse" aria-label="library" data-uk-tooltip="title: Βιβλιοθήκη; pos: right">
          <FontAwesomeIcon icon={faLayerGroup} className={`nav-menu-icon ${((path.pathname == '/browse') && (!type) && (!year)) ? 'active-nav' : ''} `} />
        </Link>
        <hr className="nav-seperator" />
        <Link to="/browse?type=videos" aria-label="videos" data-uk-tooltip="title: Βίντεο; pos: right">
          <FontAwesomeIcon icon={faPlay} className={`nav-menu-icon ${type == 'videos' ? 'active-nav' : ''} `} />
        </Link>
        <Link to="/browse?type=live-streaming" aria-label="live-streaming" data-uk-tooltip="title: Live Streaming; pos: right">
          <FontAwesomeIcon icon={faVideo} className={`nav-menu-icon ${type == 'live-streaming' ? 'active-nav' : ''} `} />
        </Link>
        <hr className="nav-seperator" />
        <Link to="/units" aria-label="units" data-uk-tooltip="title: Υλικό ανά μονάδα; pos: right">
          <FontAwesomeIcon icon={faBuilding} className={`nav-menu-icon ${path.pathname == '/units' ? 'active-nav' : ''} `} />
        </Link>
        <Link to="/browse?year=2023" aria-label="year" data-uk-tooltip="title: Υλικό ανά έτος; pos: right">
          <FontAwesomeIcon icon={faCalendarDays} className={`nav-menu-icon ${(year && year != 'all') ? 'active-nav' : ''} `} />
        </Link>
        <Link to="/today" aria-label="today" data-uk-tooltip="title: Προστέθηκαν σήμερα; pos: right">
          <FontAwesomeIcon icon={faClock} className={`nav-menu-icon ${path.pathname == '/today' ? 'active-nav' : ''} `} />
        </Link>
        <Link to="/categories" aria-label="categories" data-uk-tooltip="title: Κατηγορίες; pos: right">
          <FontAwesomeIcon icon={faTags} className={`nav-menu-icon ${path.pathname == '/categories' ? 'active-nav' : ''} `} />
        </Link>
      </div>
      </div>
    </div>
  );
};

export default Navigation;