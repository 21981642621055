import React from "react";
import { 
    FacebookShareButton, 
    LinkedinShareButton, 
    TwitterShareButton, 
    ViberShareButton, 
    FacebookMessengerShareButton, 
    FacebookIcon, 
    LinkedinIcon, 
    TwitterIcon, 
    ViberIcon, 
    FacebookMessengerIcon
  } from 'react-share';

const ShareBox = (props) => {
    const bgStyle = {};
    bgStyle.fill = props.bgColor;
    const iconColor = props.iconColor;
    const url = window.location.origin + '/video/' + props.videoID;
    const videoTitle = props.videoTitle;

    return (
        <React.Fragment>
            <div id="toggle-shareBox" className="shareBox uk-margin-small uk-hidden">      
                <FacebookShareButton url={url} quote={videoTitle} >
                    <FacebookIcon size={32} bgStyle={bgStyle} iconFillColor={iconColor} round />
                </FacebookShareButton>
                
                <LinkedinShareButton url={url} quote={videoTitle}>
                    <LinkedinIcon size={32} bgStyle={bgStyle} iconFillColor={iconColor} round />
                </LinkedinShareButton>

                <TwitterShareButton url={url} quote={videoTitle}>
                    <TwitterIcon size={32} bgStyle={bgStyle} iconFillColor={iconColor} round />
                </TwitterShareButton>

                <ViberShareButton url={url} quote={videoTitle}>
                    <ViberIcon size={32} bgStyle={bgStyle} iconFillColor={iconColor} round />
                </ViberShareButton>

                <FacebookMessengerShareButton url={url} quote={videoTitle}>
                    <FacebookMessengerIcon bgStyle={bgStyle} iconFillColor={iconColor} size={32} round />
                </FacebookMessengerShareButton>           
            </div>      
        </React.Fragment>
    );
}
export default ShareBox;